import _axios, { post, get, requestUrl } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {
  //轮播图
  async queryBannerList(data) {
    return await requestUrl({
      url: '/back/cms/banner/query',
      method: 'POST',
      data: data,
    })
  }
  async insertBanner(data) {
    return await requestUrl({
      url: '/back/cms/banner/save',
      method: 'POST',
      data: data,
    })
  }
  async deleteBanner(data) {
    return await requestUrl({
      url: '/back/cms/banner/delete',
      method: 'POST',
      data: data,
    })
  }
  async updateBanner(data) {
    return await requestUrl({
      url: '/back/cms/banner/update',
      method: 'POST',
      data: data,
    })
  }
  // async queryBannerList(data) {
  //   return await post('/adminChain/sys/saas/banner/queryBannerList', data)
  // }
  // async insertBanner(data) {
  //   return await post('/adminChain/sys/saas/banner/insertBanner', data)
  // }
  // async deleteBanner(data) {
  //   return await get('/adminChain/sys/saas/banner/deleteBanner', data)
  // }
  // async updateBanner(data) {
  //   return await post('/adminChain/sys/saas/banner/updateBanner', data)
  // }
}

export default new CsMng()
